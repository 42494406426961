'use client';

import { useAuthRedirection } from '../../hooks/useAuthRedirection';
import { useQueryParam } from '../../hooks/useQueryParam';

export function Index() {
  const [from] = useQueryParam('from');
  useAuthRedirection({ from: from?.value });

  return null;
}
